import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { fetchAddresses } from "../invoice/productDetails";
import moment from "moment";
import { getUsers } from "../../utils/databaseHelper";
import { ClassicTable } from "../../utils/components/Styles";
import EditWrap from "../commonComponent/editWrap";

const purposeList = [
  {
    label: "Onsite",
  },
  {
    label: "self-calibration",
  },
];

const readingColumns = [
  [
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
    },
    {
      field: "standards",
      headerName: "Standards",
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      editable: true,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      editable: true,
    },
    {
      field: "expectedReturnDate",
      headerName: "Return Date",
      editable: true,
    },
    {
      field: "outwardCheckPoint",
      headerName: "Outward CheckPoint",
      editable: true,
    },
    {
      field: "inwardCheckpoint",
      headerName: "Inward Checkpoint",
      editable: true,
    },
    {
      field: "engineers",
      headerName: "Engineers",
      editable: true,
    },
    {
      field: "remark",
      headerName: "Remark",
      editable: true,
    },
  ],
];

export default function EditStandardInOut() {
  const params = useParams();
  const [companyName, setCompanyName] = useState(null);
  const [clientsOption, setClientsOption] = useState([]);
  const [clientsObj, setClientsObj] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [engineers, setEngineers] = useState([]);
  const [address, setAddress] = useState("");
  const [readingRows, setReadingRows] = useState([]);
  const [editedRows, setEditedRows] = useState({});

  const handleCellChange = (newValue, rowId, field) => {
    setEditedRows((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[rowId],
        [field]: newValue,
      },
    }));
  };

  const [standardArr, setStandardArr] = useState([]);
  const [editAccess, setEditAccess] = useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [standardDetails, setStandardDetails] = useState({
    purpose: "",
    outwardDate: "",
    siteInwardDate: "",
    expectedReturnDate: "",
    currentLocation: "",
    remark: "",
    outwardCheckPoint: "",
    standardId: [],
    userIds: [],
  });

  var refresh = () => {
    window.location.reload(false);
  };

  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...standardDetails,
    };
    newSrfObject[key] = value;
    setStandardDetails(newSrfObject);
  };

  const getClientList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        const clients = [];
        const tClientsObj = {};
        for (let i = 0; i < res.data.length; i++) {
          clients.push({
            label: res.data[i].id + ", " + res.data[i].companyName,
          });
        }
        res.data.map((e) => (tClientsObj[e.id] = e));
        setClientsObj(tClientsObj);
        setClientsOption(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getStandardList = () => {
    let url = BASE_URL;
    if (params.id == 0) {
      let query = {
        query: `SELECT * FROM standards WHERE id NOT IN (SELECT standardId FROM standardInOut WHERE standardId IN (standardId) AND outwardDate IS NOT NULL AND returnDate IS NULL OR returnDate >= NOW()) AND status = 1`,
      };
      axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
          const standardList = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].standardName != null)
              standardList.push({
                id: res.data[i].id,
                label: res.data[i].standardName,
                stId: res.data[i].stId,
              });
          }
          setStandardArr(standardList);
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    } else {
      axiosWithToken
        .get(url + "standards?_where=(status,eq,1)")
        .then((res) => {
          const standardList = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].standardName != null)
              standardList.push({
                id: res.data[i].id,
                label: res.data[i].standardName,
                stId: res.data[i].stId,
              });
          }
          setStandardArr(standardList);
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    }
  };

  const getEngineers = () => {
    getUsers(2, (res) => {
      return res.data.map((e) => {
        return { ...e, label: e.userName };
      });
    })
      .then((engineers) => {
        setEngineers(engineers);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const handleSubmit = (event) => {
    let url = BASE_URL;
    if (params.id == 0) {
      if (!companyName) {
        toast.error("Select Client!");
        return;
      }
      if (
        !standardDetails.standardId ||
        standardDetails.standardId.length === 0
      ) {
        toast.error("Select Standards!");
        return;
      }

      if (!standardDetails?.outwardDate) {
        toast.error("Select Outward Date!");
        return;
      }
      if (!standardDetails?.expectedReturnDate) {
        toast.error("Select Expected Return Date!");
        return;
      }
      if (!standardDetails.userIds || standardDetails.userIds.length === 0) {
        toast.error("Select at least one Engineer!");
        return;
      }
      const standardIds = standardDetails?.standardId
        ? standardDetails.standardId
            ?.split(",")
            ?.map((id) => parseInt(id.trim(), 10))
        : [];

      const payloads = standardIds.map((standardId) => ({
        clientId: companyName || null,
        address: address || null,
        purpose: standardDetails?.purpose,
        outwardDate: standardDetails?.outwardDate
          ? moment(standardDetails?.outwardDate).format("YYYY-MM-DD")
          : null,
        expectedReturnDate: standardDetails?.expectedReturnDate
          ? moment(standardDetails?.expectedReturnDate).format("YYYY-MM-DD")
          : null,
        siteInwardDate: standardDetails?.siteInwardDate
          ? moment(standardDetails?.siteInwardDate).format("YYYY-MM-DD")
          : null,
        remark: standardDetails?.remark || null,
        standardId,
        outwardCheckPoint: standardDetails?.outwardCheckPoint || null,
        userId: standardDetails?.userIds || null,
      }));

      console.log(payloads);

      axiosWithToken
        .post(url + "standardInOut/bulk", payloads)
        .then((res) => {
          toast.success("standards In/Out created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    } else {
      const patchRequests = Object.keys(editedRows).map((rowId) => {
        const updatedData = editedRows[rowId];
        if (updatedData.returnDate) {
          updatedData.returnDate = moment(updatedData.returnDate).format(
            "YYYY-MM-DD"
          ) || null;
        }
        console.log({ updatedData });
        return axiosWithToken.patch(url + `standardInOut/${rowId}`, updatedData);
      });
      
      Promise.all(patchRequests)
        .then((responses) => {
          toast.success("standards In/Out Updated successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
      
    }
  };

  const debouncedFetchAddresses = async (addressIds, shippingAddressIds) => {
    setLoading(true);
    const newAddressArray = await fetchAddresses(
      addressIds,
      shippingAddressIds
    );
    setAllAddress(newAddressArray);
    setLoading(false);
  };

  const getStandardInOutListbyClients = () => {
    let url = BASE_URL;

    let query = {
      query: `SELECT si.*, a.address FROM standardInOut AS si LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(si.address, ',', 1), ',', -1) WHERE si.clientId = ${params.id} AND si.status = 1`,
    };
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        console.log(res.data);
        setReadingRows(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getStandardInOutList = () => {
    let url = BASE_URL;
    let query = {
      query: `SELECT c.companyName, c.address, si.standardId,si.purpose, si.outwardDate, si.expectedReturnDate, si.siteInwardDate, si.userId, si.remark, si.outwardCheckPoint, si.id AS standardInOutId, si.clientId FROM standardInOut si JOIN clients c ON si.clientId = c.id WHERE si.clientId = ${params.id}`,
    };
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        console.log({ res });
        setCompanyName(res.data[0].companyName);
        debouncedFetchAddresses(res.data[0].address, "");
        setStandardDetails({
          ...standardDetails,
          // standardId: res.data[0].standardId,
          purpose: res.data[0].purpose,
          remark: res.data[0].remark,
          outwardCheckPoint: res.data[0].outwardCheckPoint,
          outwardDate: moment(res.data[0].outwardDate).format("YYYY-MM-DD"),
          siteInwardDate: moment(res.data[0].siteInwardDate).format(
            "YYYY-MM-DD"
          ),
          actualStandardDate: moment(res.data[0].actualStandardDate).format(
            "YYYY-MM-DD"
          ),
        });
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };
  
  useEffect(() => {
    getClientList();
    getEngineers();
  }, []);

  useEffect(() => {
    getStandardList();
  }, [companyName]);

  useEffect(() => {
    if (params.id != 0) {
      getStandardInOutList();
      getStandardInOutListbyClients();
    }
  }, [params.id != 0]);

  return (
    <EditWrap>
      <Typography variant="h6" component="h6" textAlign={"left"}>
        {params?.id != 0 ? "Edit" : "Create"} Master In Entry
      </Typography>
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {params.id != 0 ? (
            <TextField
              id="outlined-basic"
              label="Client"
              size="small"
              disabled
              value={companyName || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => setCompanyName(e.target.value)}
            />
          ) : (
            <Autocomplete
              id="outlined-basic"
              label="Client"
              size="small"
              // value={companyName}
              options={clientsOption}
              getOptionLabel={(option) => `${option.id}, ${option.companyName}`}
              renderInput={(params) => <TextField {...params} label="Client" />}
              onChange={(event, value) => {
                if (value != null) {
                  const addressIds = value?.address;
                  const shippingAddressIds = value?.shippingAddress;
                  debouncedFetchAddresses(addressIds, shippingAddressIds);
                  setCompanyName(parseInt(value.id));
                  setAddress(clientsObj[parseInt(value.id)].address);
                } else {
                  setCompanyName(value);
                  setAddress("");
                }
              }}
            />
          )}
        </Grid>
        {params.id == 0 && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {allAddress?.length === 1 ? (
              <TextField
                value={allAddress?.length === 1 ? allAddress[0]?.address : ""}
                id="outlined-basic"
                label="Address *"
                fullWidth
                variant="outlined"
                size="small"
                disabled
                onChange={(event, value) => {
                  setAddress(value?.id);
                }}
              />
            ) : (
              <Autocomplete
                disabled={params.id != 0}
                options={allAddress}
                size="small"
                getOptionLabel={(option) => option?.address}
                renderInput={(params) => (
                  <TextField {...params} label="Address *" variant="outlined" />
                )}
                onChange={(event, value) => {
                  setAddress(value?.id);
                }}
              />
            )}
          </Grid>
        )}

        {params.id == 0 && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                multiple
                id="outlined-basic"
                size="small"
                options={standardArr}
                // value={standardArr.filter((option) =>
                //   standardDetails.standardId.includes(option.id)
                // )}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label || ""}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.stId}: {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Standards"
                    placeholder="select multiple Standards"
                  />
                )}
                onChange={(event, value) => {
                                    const selectedIds = value
                    .map((option) => option.id)
                    .join(",");
                  updateSrfObject("standardId", selectedIds);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                size="small"
                id="outlined-basic"
                options={purposeList}
                label="Purpose"
                value={standardDetails?.purpose}
                renderInput={(params) => (
                  <TextField {...params} label="Purpose" />
                )}
                // disabled
                fullWidth
                variant="outlined"
                onChange={(event, value) => {
                  if (value != null) {
                    updateSrfObject("purpose", value.label);
                  } else {
                    updateSrfObject("purpose", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  label="Site Inward Date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    standardDetails?.siteInwardDate
                      ? new Date(standardDetails?.siteInwardDate)
                      : ""
                  }
                  onChange={(newValue) => {
                    updateSrfObject("siteInwardDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  label="Outward date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    standardDetails?.outwardDate
                      ? new Date(standardDetails?.outwardDate)
                      : ""
                  }
                  onChange={(newValue) => {
                    updateSrfObject("outwardDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  label="Expected return date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    standardDetails?.expectedReturnDate
                      ? new Date(standardDetails?.expectedReturnDate)
                      : ""
                  }
                  onChange={(newValue) => {
                    updateSrfObject("expectedReturnDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  label="Actual return date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    standardDetails?.actualReturnDate
                      ? new Date(standardDetails?.actualReturnDate)
                      : ""
                  }
                  onChange={(newValue) => {
                    updateSrfObject("actualReturnDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  label="Actual Standard date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    standardDetails?.actualStandardDate
                      ? new Date(standardDetails?.actualStandardDate)
                      : ""
                  }
                  onChange={(newValue) => {
                    updateSrfObject("actualStandardDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                id="outlined-basic"
                multiple
                options={engineers}
                size="small"
                // value={engineers.filter((option) =>
                //   standardDetails.userIds.includes(option.id)
                // )}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField {...params} label="Engineer Name" />
                )}
                // disabled
                fullWidth
                variant="outlined"
                onChange={(_, value) => {
                  const engineerIds = value
                    .map((engineer) => engineer.id)
                    .join(",");
                  updateSrfObject("userIds", engineerIds);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Check Points"
                fullWidth
                minRows={3}
                maxRows={5}
                multiline
                size="small"
                value={standardDetails?.outwardCheckPoint}
                variant="outlined"
                onChange={(e) => {
                  updateSrfObject("outwardCheckPoint", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label="Remarks"
                value={standardDetails?.remark}
                size="small"
                fullWidth
                minRows={3}
                maxRows={5}
                multiline
                variant="outlined"
                onChange={(e) => {
                  updateSrfObject("remark", e.target.value);
                }}
              />
            </Grid>
          </>
        )}
      </Grid>

      <br />
      <hr />
      {params.id != 0 && (
        <>
          <h4 style={{ "marginBottom": "15px" }}>Master Records</h4>
          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTable>
              <Table sx={{ minWidth: 660 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {readingColumns[0].map((column) => (
                      <TableCell key={column.field}>
                        {" "}
                        <Typography noWrap sx={{fontSize:"14px"}}>{column.headerName}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {readingRows.map((row, index) => {
                    const standard = standardArr?.find(
                      (el) => el.id === row.standardId
                    );
                    const userIds = row.userId?.split(',')?.map(id => parseInt(id.trim(), 10)); // Split userId string and convert each ID to an integer
                    const engineersName = engineers
                      ?.filter(
                        (option) => userIds?.includes(option.id)
                      )
                      ?.map((engineer) => engineer.userName);
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{fontSize:"12px"}}>{index + 1}</TableCell>
                        <TableCell sx={{fontSize:"12px"}}>
                          {standard
                            ? `${standard.stId}: ${standard.label}`
                            : ""}
                        </TableCell>
                        <TableCell sx={{fontSize:"12px"}}>{row.address}</TableCell>
                        <TableCell sx={{fontSize:"12px"}}>{row.purpose}</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              slotProps={{
                                textField: { size: "small", fullWidth: true },
                              }}
                              inputFormat="dd/MM/yyyy"
                              format="dd/MM/yyyy"
                              value={
                                row.returnDate ? new Date(row.returnDate) : ""
                              }
                              onChange={(newValue) => {
                                handleCellChange(
                                  newValue,
                                  row.id,
                                  "returnDate"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell sx={{fontSize:"12px"}}>{row.outwardCheckPoint}</TableCell>
                        <TableCell>
                          <textarea
                            id="outlined-basic"
                            label="Inward Checkpoint"
                            value={editedRows[row.id]?.inwardCheckpoint || row.inwardCheckpoint}
                            size="small"
                            placeholder="Inward Checkpoint"
                            fullWidth
                            InputProps={{sx:{height:"35px"}}}
                            variant="outlined"
                            onChange={(e) => {
                             handleCellChange(
                                e.target.value,
                                row.id,
                                "inwardCheckpoint"
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{fontSize:"12px"}}>{engineersName?.join(", ")}</TableCell>
                        <TableCell sx={{fontSize:"12px"}}>{row.remark}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
        </>
      )}

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
        </Tooltip>
      </Toolbar>
    </EditWrap>
  );
}
