import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { editstdMastersEQPGuide } from "./guide";
import "react-toastify/dist/ReactToastify.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassicTable } from "../../../utils/components/Styles";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import MultiValueInputModal from "../../common/multiValueInputModal";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import {
  modalConfigs,
  mode,
  etParameters,
  paratype,
  readingColumns,
  parameters,
} from "../../../constants/masterEQPConstants";
import { handleSubmit } from "./submitHandler";
import {
  getDiscipline,
  getRanges,
  getStandard,
  getUnitMasterArray,
} from "./fetchHandler";
import { useSettings } from "../../srf/editSrf/fetchHandlers";
import EditWrap from "../../commonComponent/editWrap";
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function EditStandard(props) {
  const params = useParams();
  const editstdMastersEQPGuides = editstdMastersEQPGuide();
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standardDetails, setStandardDetails] = React.useState({
    standardName: "",
    ids: "",
    serialNo: "",
    make: "",
    model: "",
    location: "",
    calDate: "",
    validUpto: "",
    traceability: "",
    certificateNumber: "",
    EQMType: 1,
    materialType: "",
    certificateUrl: "",
  });
  const [disciplines, setDisciplineArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [standardRanges, setStandardRanges] = React.useState([]);
  const [curruntsetDecipline, setDeciplines] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [isTrainrainingMasterCertificate, setIstrainrainingMasterCertificate] =
    React.useState(false);

  const [settings, fetchSettings] = useSettings({});
  const [materialTypeOption, setMaterialTypeOption] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const [disciplineID, setDisciplineID] = React.useState(0);
  const [editingRowIndex, setEditingRowIndex] = React.useState(-1);

  const parseField = (fieldValue) => {
    // Ensure fieldValue is treated as a string
    const strValue = typeof fieldValue === "string" ? fieldValue : "";
    const [value = "", unit = ""] = strValue.split("#");
    return [value, unit];
  };
  const initiateRanges = () => {
    // Get columns for the current discipline
    const columns = readingColumns[disciplineID];
    const buildRow = (range) => {
      const row = { id: range.id };
      // Populate row based on column definitions
      columns.forEach((column) => {
        const field = column.field;
        // Skip processing if field is 'action'
          if (field === "action") {
            return;
          }

        if (field === "from_range" || field === "to_range") {
          // Handle 'from_range' and 'to_range' separately
          const [rangeValue, rangeUnit] = parseField(
            range.rangeName.split("|")[field === "from_range" ? 0 : 1] || ""
          );
          row[field] = rangeValue;
          row[`${field}_unit`] = rangeUnit;
        } else if (field !== "id") {
          // General handling for other fields except 'id'
          const [value, unit] = parseField(range[field]);
          row[field] = value;
          row[`${field}_unit`] = unit;
        }
      });

      return row;
    };

    const rows = standardRanges.map((range) => buildRow(range));
    setReadingRows(rows);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setStandardDetails({
            ...standardDetails,
            certificateUrl: res.data,
          });
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function extractUnit(value) {
    const groups = value?.split("||");
    const unit = groups
      ?.map((group) => {
        const parts = group?.split("|");
        if (parts?.length === 1 || parts?.length === 2) {
          return parts[parts?.length - 1]?.split("#")[1];
        }
        return null;
      })
      ?.find((unit) => unit !== null);

    return unit ? `#${unit}` : "";
  }
  const updateSrfObject = (key, value) => {
    let newSrfObject = { ...srfObject };
    newSrfObject[key] = value;

    if (key === "masterrange") {
      const unit = extractUnit(value);
      newSrfObject.masteraccuracy = `${unit}`;
      newSrfObject.masterleastcount = `${unit}`;
    }

    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, field, value) => {
    setReadingRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowToUpdate = { ...updatedRows[rowIndex] };

      if (field.endsWith("_unit")) {
        // Update the unit field
        rowToUpdate[field] = value;
      } else {
        // Update the value field and keep the current unit
        rowToUpdate[field] = value;
      }
      updatedRows[rowIndex] = rowToUpdate;
      return updatedRows;
    });
  };
  const addRangeRow = (tableIndex) => {
    const columns = readingColumns[disciplineID];
    const newRow = columns.reduce((row, { field }) => {
      if (!["action", "id"].includes(field)) {
        row[field] = "";
        row[`${field}_unit`] = "";
      }
      return row;
    }, {});

    setReadingRows((prev) => {
      let rowToAdd;
      if (tableIndex !== undefined) {
        rowToAdd = { ...prev[tableIndex], id: 0 };
      } else {
        rowToAdd = { ...newRow, id: 0 };
      }
      const updatedRows = [...prev, rowToAdd];
      setEditingRowIndex(updatedRows.length - 1);
      return updatedRows;
    });
    setTotalRows((prev) => prev + 1);
  };
  const deleteReadingRow = (rowIndex, id) => {
    console.log(rowIndex, id);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (!isConfirmed) {
      return;
    }
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined && id) {
      let url = BASE_URL;
      axiosWithToken
        .delete(url + `standardRanges/${id}`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => console.log("Something Went Wrong!", error));
    }
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
    setTotalRows(totalRows - 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const renderEditMasterRanges = (index, row) => {
    const columns = readingColumns[disciplineID];
    return (
      <>
        {columns.map((column) => {
          const field = column.field;
          const cell = row[field];
          const unitField = `${field}_unit`;

          // Skip rendering for 'id' and 'action' fields
          if (field === "id" || field === "action") {
            return null;
          }

          // Render Autocomplete for 'mode' field
          if (field === "mode") {
            return (
              <TableCell key={field}>
                <Autocomplete
                  style={{ width: "150px" }}
                  size="small"
                  options={mode.map((e) => ({ label: e }))}
                  value={cell}
                  renderInput={(params) => (
                    <TextField {...params} label="Mode" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    const isValid = mode.some(
                      (option) => option === newInputValue
                    );
                    updateCellValue(
                      index,
                      field,
                      isValid ? newInputValue : null
                    );
                  }}
                />
                <select
                  disabled
                  style={{ width: "150px" }}
                  value={row[unitField] || ""}
                  onChange={(e) => {
                    updateCellValue(index, unitField, e.target.value);
                  }}
                >
                  <option value={""} key={""} selected></option>
                  {units?.map((unit) => (
                    <option value={unit.symbol} key={unit.symbol}>
                      {unit.symbol}
                    </option>
                  ))}
                </select>
              </TableCell>
            );
          }

          // Render Autocomplete for 'etParameter' field
          if (field === "etParameter") {
            const parameterOptions =
              row.mode === "Source"
                ? etParameters.Source
                : etParameters.Measure;

            return (
              <TableCell key={field}>
                <Autocomplete
                  style={{ width: "150px" }}
                  size="small"
                  options={parameterOptions.map((e) => ({ label: e }))}
                  value={cell}
                  renderInput={(params) => (
                    <TextField {...params} label={`${field}`} />
                  )}
                  onInputChange={(event, newInputValue) => {
                    const isValid = parameterOptions.some(
                      (option) => option === newInputValue
                    );
                    updateCellValue(
                      index,
                      field,
                      isValid ? newInputValue : null
                    );
                  }}
                  disabled={!row.mode}
                />
                <select
                  disabled
                  style={{ width: "150px" }}
                  value={row[unitField] || ""}
                  onChange={(e) => {
                    updateCellValue(index, unitField, e.target.value);
                  }}
                >
                  <option value={""} key={""} selected></option>
                  {units?.map((unit) => (
                    <option value={unit.symbol} key={unit.symbol}>
                      {unit.symbol}
                    </option>
                  ))}
                </select>
              </TableCell>
            );
          }

          // Render Autocomplete for 'parameter' field
          if (field === "parameter") {
            return (
              <TableCell key={field}>
                <Autocomplete
                  style={{ width: "150px" }}
                  size="small"
                  options={parameters}
                  value={cell}
                  renderInput={(params) => (
                    <TextField {...params} label={`${field}`} />
                  )}
                  onInputChange={(event, newInputValue) => {
                    const isValid = parameters.some(
                      (option) => option.label === newInputValue
                    );
                    updateCellValue(
                      index,
                      field,
                      isValid ? newInputValue : null
                    );
                  }}
                />
                <select
                  disabled
                  style={{ width: "150px" }}
                  value={row[unitField] || ""}
                  onChange={(e) => {
                    updateCellValue(index, unitField, e.target.value);
                  }}
                >
                  <option value={""} key={""} selected></option>
                  {units?.map((unit) => (
                    <option value={unit.symbol} key={unit.symbol}>
                      {unit.symbol}
                    </option>
                  ))}
                </select>
              </TableCell>
            );
          }

          // Render Autocomplete for 'paratype' field
          if (field === "paratype") {
            const showAutocomplete =
              (row.mode === "Source" &&
                (row.etParameter === "Oscilloscope" ||
                  row.etParameter === "Temperature Simulation")) ||
              (row.mode === "Measure" &&
                row.etParameter === "Temperature Simulation");

            const paratypeOptions = showAutocomplete
              ? paratype[row.mode]?.[row.etParameter] || []
              : [];

            return (
              <TableCell key={field}>
                {showAutocomplete ? (
                  <Autocomplete
                    style={{ width: "150px" }}
                    size="small"
                    options={paratypeOptions?.map((e) => ({ label: e }))}
                    value={cell}
                    renderInput={(params) => (
                      <TextField {...params} label="Parameter Type *" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      const isValid = paratypeOptions?.some(
                        (option) => option === newInputValue
                      );
                      updateCellValue(
                        index,
                        field,
                        isValid ? newInputValue : null
                      );
                    }}
                  />
                ) : (
                  <TextField
                    size="small"
                    stye={{width:"150px"}}
                    value={cell}
                    inputProps={{ autoComplete: "off" }}
                    onChange={(e) => {
                      updateCellValue(index, field, e.target.value);
                    }}
                  />
                )}
                <select
                  disabled
                  style={{ width: "150px" }}
                  value={row[unitField] || ""}
                  onChange={(e) => {
                    updateCellValue(index, unitField, e.target.value);
                  }}
                >
                  <option value={""} key={""} selected></option>
                  {units?.map((unit) => (
                    <option value={unit.symbol} key={unit.symbol}>
                      {unit.symbol}
                    </option>
                  ))}
                </select>
              </TableCell>
            );
          }

          // Default
          return (
            <TableCell key={field}>
              <TextField
                size="small"
                inputProps={{ autoComplete: "off" }}
                value={cell}
                style={{width:"150px"}}
                onChange={(e) => {
                  updateCellValue(index, field, e.target.value);
                }}
              />
              <select
                style={{ width: "150px" }}
                value={row[unitField] || ""}
                onChange={(e) => {
                  updateCellValue(index, unitField, e.target.value);
                }}
              >
                <option value={""} key={""} selected></option>
                {units?.map((unit) => (
                  <option value={unit.symbol} key={unit.symbol}>
                    {unit.symbol}
                  </option>
                ))}
              </select>
            </TableCell>
          );
        })}
      </>
    );
  };
  const renderViewMasterRanges = (index, row) => {
    const columns = readingColumns[disciplineID];
    return (
      <>
        {columns.map((column) => {
          const field = column.field;

          // Skip rendering for 'id' and 'action' fields
          if (field === "id" || field === "action") {
            return null;
          }

          const cell = row[field];
          const unitField = `${field}_unit`;
          const unitSymbol = units?.find(
            (unit) => unit.symbol === row[unitField]
          )?.symbol;

          return (
            <TableCell
              key={field}
              onClick={() => {
                setEditingRowIndex(index);
              }}
            >
              <Typography noWrap>
                {cell && <span>{`${cell.split("#")[0]} `}</span>}
                {unitSymbol && <span>{unitSymbol}</span>}
              </Typography>
            </TableCell>
          );
        })}
      </>
    );
  };
  const renderTableBody = () => {
    return (
      <TableBody>
        {readingRows?.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <ContentCopyIcon
                  id="edit_stdMastersEQP_master_copymasterrange"
                  style={{ cursor: "pointer", color: "#1976d2" }}
                  onClick={(e) => {
                    addRangeRow(index);
                  }}
                />
                <DeleteIcon
                  id="edit_stdMastersEQP_dispatchchalland_delete"
                  style={{ cursor: "pointer", color: "#dc3545" }}
                  onClick={(e) => {
                    deleteReadingRow(index, row.id);
                  }}
                />
              </Box>
            </TableCell>
            <TableCell>{index + 1}</TableCell>
            {editingRowIndex == index && renderEditMasterRanges(index, row)}
            {editingRowIndex !== index && renderViewMasterRanges(index, row)}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  useEffect(async () => {
    const disciplineData = await getDiscipline();
    const unitData = await getUnitMasterArray();
    setDisciplineArray(disciplineData);
    setUnitArray(unitData);
    fetchSettings(["materialType", "TrainingAndMasterCertificate"]);
  }, []);

  useEffect(() => {
    const trainandMaster =
      settings?.TrainingAndMasterCertificate?.value === "true";
    setIstrainrainingMasterCertificate(trainandMaster);

    const generateMaterialTypeOptions = () => {
      if (!settings || !settings.materialType) {
        return [];
      }
      return settings.materialType.value.split(",").map((option) => {
        const [label, value] = option.split(":");
        return { label, value };
      });
    };
    const options = generateMaterialTypeOptions();
    setMaterialTypeOption(options);
  }, [settings?.materialType, settings?.TrainingAndMasterCertificate]);

  useEffect(async () => {
    if (params.id != 0) {
    const { totalCount, standardRanges } = await getRanges(
      params.id,
      page,
      rowsPerPage
    );
    setTotalRows(totalCount);
    const filteredStandardRanges = standardRanges.map((range) => {
      return Object.fromEntries(
        Object.entries(range).filter(([key, value]) => {
          if (value === null || value === undefined || value === "") {
            return false;
          }
          if (typeof value === "string" && value.trim() === "") {
            return false;
          }
          return true;
        })
      );
    });
    sessionStorage.setItem(
      "masterEQPstandardRanges",
      JSON.stringify(filteredStandardRanges)
    );
    setStandardRanges(standardRanges);
    }
  }, [params.id, page, rowsPerPage]);

  useEffect(() => {
    if (params.id != 0) {
      initiateRanges();
    }
  }, [params.id, standardRanges]);

  useEffect(() => {
    if (params.id != 0) {
      getStandard(
        params.id,
        unitArray,
        standardDetails,
        setStandardDetails,
        createSrfObject,
        setDeciplines,
        setUnits,
        setDisciplineID
      );
    }
  }, [unitArray]);

  useEffect(() => {
    getRanges();
  }, [rowsPerPage, page]);
  
  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {params.id != 0 ? "Edit" : "Create"} Master EQP
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editstdMastersEQPGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Autocomplete
            size="small"
            id="edit_stdMastersEQP_discipline"
            options={disciplines.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            value={curruntsetDecipline || ""}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              setDeciplines(newInputValue);
              disciplines.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    setDisciplineID(0);
                  } else if (el.disciplineKey == "1") {
                    setDisciplineID(1);
                  } else if (el.disciplineKey == "2") {
                    setDisciplineID(2);
                  } else if (el.disciplineKey == "3") {
                    setDisciplineID(3);
                  }
                }
              });

              const unitArr = [""];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );
            }}
            disabled={params.id != 0}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_eqp"
            label="Master EQP Name *"
            size="small"
            value={standardDetails?.standardName}
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                standardName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_id"
            label="id *"
            size="small"
            value={standardDetails?.ids}
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                ids: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_srno"
            label="Sr No. *"
            size="small"
            value={standardDetails?.serialNo}
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                serialNo: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_make"
            label="Make"
            size="small"
            value={standardDetails?.make}
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                make: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_model"
            label="Model "
            size="small"
            value={standardDetails?.model}
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                model: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_traceability"
            label="Tracability "
            size="small"
            fullWidth
            value={standardDetails?.certificateNumber}
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                certificateNumber: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_calagency"
            label="Cal. Agency."
            size="small"
            fullWidth
            value={standardDetails?.traceability}
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                traceability: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_location"
            label="Location "
            size="small"
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: "off",
            }}
            value={standardDetails?.location}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                location: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <div
            style={{
              display: "flex",
              columnGap: "20px",
              rowGap: "20px",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {modalConfigs.map((config, index) => (
              <MultiValueInputModal
                key={`${index},${srfObject.disciplineId}`}
                buttonLabel={config.buttonLabel}
                transformedStringLabel={config.transformedStringLabel}
                inputStates={srfObject}
                updateInputObject={updateSrfObject}
                disciplineId={+curruntsetDecipline?.split(", ")?.[0]}
              />
            ))}
          </div>
        </Grid>
        {isTrainrainingMasterCertificate && (
          <>
            {standardDetails?.certificateUrl && (
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <b style={{ width: "50%", m: "auto" }}>
                  <a
                    href={standardDetails?.certificateUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View File
                  </a>
                </b>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Button
                id="create_document_uploadfile"
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ width: "100%", m: "auto" }}
              >
                {standardDetails?.certificateUrl == null ||
                standardDetails?.certificateUrl == ""
                  ? "Upload file"
                  : standardDetails?.certificateUrl.split("_").pop()}
                <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Autocomplete
            size="small"
            id="edit_stdMastersEQP_master_materialType"
            value={
              materialTypeOption.find(
                (option) => option.value === standardDetails?.materialType
              )?.label || ""
            }
            options={materialTypeOption.map((option) => option.label)}
            renderInput={(params) => (
              <TextField {...params} label="Material Type " />
            )}
            onInputChange={(event, newInputValue) => {
              const selectedOption = materialTypeOption.find(
                (option) => option.label === newInputValue
              );
              if (selectedOption) {
                setStandardDetails({
                  ...standardDetails,
                  materialType: selectedOption.value,
                });
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          id="edit_stdMastersEQP_master_caldate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Cal Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                standardDetails?.calDate
                  ? new Date(standardDetails?.calDate)
                  : ""
              }
              onChange={(newValue) => {
                setStandardDetails({
                  ...standardDetails,
                  calDate: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          id="edit_stdMastersEQP_master_duedate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Due Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                standardDetails?.validUpto
                  ? new Date(standardDetails?.validUpto)
                  : ""
              }
              onChange={(newValue) => {
                setStandardDetails({
                  ...standardDetails,
                  validUpto: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={12} md={4} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={standardDetails?.EQMType == 2}
                onChange={(e) => {
                  setStandardDetails({
                    ...standardDetails,
                    EQMType: e.target.checked ? 2 : 1,
                  });
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">Is Supportive Instrument</Typography>
            }
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <h4 style={{ marginBottom: "0px" }}>Master Ranges</h4>
      <Button
        id="edit_stdMastersEQP_master_addmasterrange"
        style={{ float: "right", marginBottom: "15px" }}
        onClick={(e) => {
          addRangeRow();
        }}
        disabled={!curruntsetDecipline}
        variant="contained"
        size="small"
      >
        add master uncertinity
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[disciplineID]?.map((column, index) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </ClassicTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </div>
      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip
          title={
            !(editAccess?.includes(1) || editAccess?.includes(0))
              ? "You don't have access"
              : ""
          }
        >
          <Button
            id="edit_stdMastersEQP_dispatchchalland_update"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            onClick={() => {
              handleSubmit(
                props,
                standardDetails,
                srfObject,
                readingRows,
                params
              );
            }}
          >
            {params.id != 0 ? "update" : "save"}
          </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />
    </EditWrap>
  );
}
