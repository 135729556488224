import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { TextField, Autocomplete, Modal, useMediaQuery, Table, Grid } from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },
  {
    id: "companyName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },

  {
    id: "date",
    label: "date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "workOrderNumber",
    label: "Work Order Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "place",
    label: "Place",
    align: "left",
    minWidth: 40,
  },
  {
    id: "courierPartner",
    label: "Courier Partner",
    align: "left",
    minWidth: 40,
  },
  {
    id: "discription",
    label: "Discription",
    align: "left",
    minWidth: 40,
  },
  {
    id: "purpose",
    label: "Purpose",
    align: "left",
    minWidth: 40,
  },
  {
    id: "trackingNo",
    label: "Tracking No",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  companyName,
  date,
  workOrderNumber,
  place,
  courierPartner,
  discription,
  purpose,
  trackingNo
) {
  return {
    id,
    companyName,
    date,
    workOrderNumber,
    place,
    courierPartner,
    discription,
    purpose,
    trackingNo,
  };
}

export default function CourierList() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [supportiveInstrumentsListData, setSupportiveInstrumentsListData] =
    React.useState([]);
  const [courierList, setCourierList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Client");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `clientCourier/${id}`)
      .then((res) => {
        toast("client Courier was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  function getCourierList() {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "cl.companyName",
        "Work Order Number": "workOrderNumber",
        "Tracking No": "trackingNo",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cc.*, cl.companyName FROM clientCourier AS cc LEFT JOIN clients AS cl ON cc.clientID = cl.id  ${whereConditions} order by id DESC ${pagination_settings}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCourierList([...res.data]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    axiosWithToken
      .get(BASE_URL + `clientCourier/count`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getCourierList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Client");
    setSearchKey("");
    setSearched(false); 
  };
  
  useEffect(() => {
    if(searched==false){
      getCourierList();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);
  
  useEffect(() => {
    getTotalRows();
    getCourierList();
  }, [rowsPerPage, page]);

  const supportInstData = () => {
    var rowDaata = [];
    for (let i = 0; i < courierList.length; i++) {
      rowDaata.push(
        createData(
          courierList[i].id,
          courierList[i].companyName,
          courierList[i].date
            ? moment(courierList[i].date).format("DD-MM-YYYY")
            : "",
          courierList[i].workOrderNumber,
          courierList[i].place,
          courierList[i].courierPartner,
          courierList[i].discription,
          courierList[i].purpose,
          courierList[i].trackingNo
        )
      );
    }
    setSupportiveInstrumentsListData(rowDaata);
  };

  const filterSection = () => (
    <Grid
    container
    spacing={2}
    alignItems="center"
    style={{ padding: "5px 0px" }}
  >
    <Grid item xs={12} sm={12} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            width: "100%"
          }}
          size="small"
          value={searchBy}
          id="combo-box-demo"
          options={[
            { key: "cl.companyName", label: "Client" },
            { key: "workOrderNumber", label: "Work Order Number" },
            { key: "trackingNo", label: "Tracking No" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false); 
          }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false); 
          }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={1}>
        <Button
          variant="contained"
          size="small"
          style={{ height: "40px" }}
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
        </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    supportInstData();
  }, [courierList]);

  return (
    <ListWrap>
      {filterModal()}
      <Box sx={{ display: "flex", gap: "1rem",justifyContent:"space-between" }}>  
      <Toolbar style={{ padding: "0px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/courier/createCourier"
        >
          <b>create COURIER</b>
        </Button>
      </Toolbar>
      <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          </Box>
        {!isMobile && filterSection()}
      
    <TableContainer>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supportiveInstrumentsListData?.length > 0 ? (
            supportiveInstrumentsListData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Courier" placement="top-start">
                        <Button
                          component={Link}
                          to={`/courier/editCourier/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Courier" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete Courier?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
               <h6><b>Data Not Found</b></h6>
              </TableCell>
            </TableRow>
          )} 
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
    </ListWrap>
  );
}
